.collapsibleBox {
    background-color: rgb(27, 27, 28);
    color: var(--color-text-primary);
    padding: 26px;
    margin: 20px;
    font-size: 0.9em;
}

.collapsibleBox p {
    margin: 0;
    font-size: 0.85em;
    opacity: 0.6;
}

.collapsibleBox p:not(:last-child) {
    margin-bottom: 10px;
}

.collapsibleBox-header {
    margin-top: 0;
    display: flex;
    align-items: center;
}

.collapsibleBox-content {
    margin-top: 10px;
}

.expand-button {
    background-color: transparent;
    border: none;
    color: var(--color-text-primary);
    padding: 0;
    margin-right: 10px;
}