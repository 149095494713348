.Instructional {
    margin: 20px;
    padding: 10px;
}

.Instructional h2 {
    font-size: 20px;
}

.Instructional a {
    background-color: #3E43CA;
    display: inline-block;
    padding: 10px;
    margin: 5px;
    color: white;
    text-decoration: none;
    border-radius: 5px;
}

.Instructional p {
    font-size: 16px;
}

.Instructional img {
    max-width: 100%;
    max-height: 400px;
    height: auto;
    margin: auto;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .Instructional {
        margin: 0;
        padding: 0;
    }

    .Instructional h2 {
        font-size: 16px;
    }

    .Instructional p {
        font-size: 12px;
    }

    .Instructional img {
        max-width: 100%;
        max-height: 200px;
        height: auto;
        margin: auto;
        text-align: center;
    }
}
