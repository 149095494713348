.list-body {
  font-family: 'Roboto Mono', monospace;
  color: var(--color-text-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0px;
  max-width: 60vw;
  text-align: left;
  font-size: calc(10px + 2vmin);
}

.list-body ol {
  font-size: calc(8px + 1vmin);
}

.list-body li {
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .list-body {
    max-width: 90vw;
  }
}
