.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

a:visited {
  color: #FFFFFF;
}

.back-button {
  display: inline-block;
  padding: 10px;
  margin: 10px;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  width: 100px;
  vertical-align: middle;
  font-size: 0.6em;
}

.App-header {
  font-family: 'Roboto Mono', monospace;
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  color: white;
}

.App-header h3 {
  font-size: calc(10px + 4vmin);
  padding: 0;
  margin: 0;
  padding-top: 40px;
  padding-bottom: 20px;
}

.App-body {
  font-family: 'Roboto Mono', monospace;
  background-color: #282c34;
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
  font-size: calc(10px + 2vmin);
  color: white;
}

.access {
  color: #b2b8c4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0px;
  max-width: 60vw;
  text-align: left;
}

.access ol {
  font-size: calc(8px + 1vmin);
}

.access li {
  margin-bottom: 20px;
}

.access li p {
  font-size: 0.8em;
  opacity: 0.6;
}

.server-status a {
  font-size: 1em;
  color: #5154bd;
}

.links {
  font-family: 'Roboto Mono', monospace;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: calc(10px + 2vmin);
  color: var(--color-text-primary);
  margin: auto;
}

.instructional-modal {
  width: 60%;
  height: 60%;
  margin: auto;
  background-color: var(--color-background-dark);
  color: var(--color-text-primary);
  position: absolute;
  inset: 40px;
  border: 1px solid var(--color-accent-primary);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.instructional-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

@media only screen and (max-width: 600px) {
  .access {
    max-width: 90vw;
  }

  .App-body {
    gap: 10px;
  }

  .instructional-modal {
    width: 70%;
    height: 80%;
  }
}
