.links-container {
    display: flex;
    gap: 40px;
    padding-bottom: 20px;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
}

.links-container a, .links-container a:visited, .links-container a:hover, .links-container a:active {
    color: var(--color-text-primary);
}

.link {
    display: inline-block;
    padding: 20px;
    margin: 5px;
    color: var(--color-text-primary);
    text-decoration: none;
    border-radius: 5px;
    width: calc(100% * (1/4) - 20px - 1px);
    vertical-align: middle;
}

@media only screen and (max-width: 600px) {
    .links-container {
        display: block;
    }

    .link {
        display: block;
        width: 80vw;
        margin-bottom: 20px;
    }
}

.close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    margin: 10px;
    vertical-align: middle;
    background-color: var(--color-accent-primary);
    color: var(--color-text-primary);
    text-decoration: none;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    text-align: center;
}
