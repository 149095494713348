:root {
    --color-accent-primary: #3F43C2;
    --color-accent-light: #5F62D9;
    --color-accent-dark: #2C30A6;
    
    --color-background-default: #292C33;
    --color-background-light: #353842;
    --color-background-dark: #1F2126;
  
    --color-text-primary: #FFFFFF;
    --color-text-secondary: #B0B3BC;
    --color-text-placeholder: #7A7D85;
  
    --color-success-default: #4CAF50;
    --color-success-light: #81C784;
    --color-success-dark: #388E3C;
  
    --color-warning-default: #FF9800;
    --color-warning-light: #FFB74D;
    --color-warning-dark: #F57C00;
  
    --color-error-default: #F44336;
    --color-error-light: #E57373;
    --color-error-dark: #D32F2F;
  
    --color-info-default: #2196F3;
    --color-info-light: #64B5F6;
    --color-info-dark: #1976D2;
  
    --color-neutral-light: #8D9099;
    --color-neutral-default: #4E5158;
    --color-neutral-dark: #35373D;
  }
  